@import '../css/shared';

.survey-page {
  .survey-question {
    text-align: center;

    .title {
      font-size: $h3-font-size;
      font-weight: 700;
    }
  }

  .survey-answer {
    padding: map-get($spacers, 2);

    &:hover {
      background: $nude;
    }

    .asset-container {
      position: relative;
      display: table;
      text-align: center;
      margin: 0 auto;

      .asset {
        border-radius: 25px;
        border: 5px solid transparent;
      }
    }

    &.selected {
      background-color: #efefef;
      .asset-container {
        .asset {
          box-sizing: border-box;
          border-color: $primary;
        }

        &:before {
          content: '';
          position: absolute;
          right: -15px;
          top: -15px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: $primary;
        }
      }
    }

    .title-container {
      text-align: center;
      .title {
        text-transform: uppercase;
        font-weight: 500;
        display: block;
      }
    }

    .content-container {
      text-transform: initial;
      text-align: left;
      font-weight: 400;
      font-size: $small-font-size;
    }
  }

  .survey-navigation {
    .survey-navigation-container {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background: $black;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
      .survey-navigation-step {
        position: relative;
        background: $white;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        border: 1px solid $black;
        overflow: hidden;
        font-weight: 500;
        font-size: $small-font-size;

        &.active {
          background: $black;
          color: $white;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
  }
  @include media-breakpoint-down(lg) {
  }
}
