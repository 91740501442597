@import '../css/shared';

.slider-a {
  .asset-container {
    aspect-ratio: 3 / 4;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      aspect-ratio: 6 / 4;
    }
    @include media-breakpoint-up(lg) {
      aspect-ratio: 21 / 9;
    }

    a {
      display: block;
    }

    img {
      width: 100%;
    }
  }

  .copy-container {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    pointer-events: none;

    .main-text-container {
      pointer-events: initial;
      @include media-breakpoint-up(lg) {
        &.text-lg-start {
          padding-left: 65px;
        }

        &.text-lg-end {
          padding-right: 65px;
        }
      }

      .title {
        font-weight: 700;
        text-transform: uppercase;
        line-height: $headings-line-height;

        @include media-breakpoint-up(lg) {
          font-size: map-get($display-font-sizes, 2);
          font-family: $display-font-family;
        }
        @include media-breakpoint-down(lg) {
          font-size: $h3-font-size;
        }
      }

      .subtitle {
        margin: 0;
        @include media-breakpoint-up(lg) {
          font-size: $h5-font-size;
          line-height: $headings-line-height;
        }
        @include media-breakpoint-up(lg) {
        }
      }
    }
  }

  // Caso in cui è presente la Cta
  &:has(.btn) {
    .subtitle {
      margin-bottom: map-get($spacers, 4);
    }
  }

  .swiper-pagination {
    pointer-events: none;
    position: absolute !important;
    bottom: map-get($spacers, 3) !important;

    .swiper-pagination-bullet {
      pointer-events: initial;
    }

    @include media-breakpoint-down(lg) {
      //display: none;
      position: relative !important;
      padding-bottom: map-get($spacers, 3);
      bottom: 0 !important;
    }
  }

  .slider-arrows {
    position: absolute;
    inset: 0;
    height: 100%;

    .slider-arrow-container {
      position: absolute;
      top: 50%;

      &.slider-arrow-left-container {
        left: 0;
      }

      &.slider-arrow-right-container {
        right: 0;
      }

      .slider-arrow-left,
      .slider-arrow-right {
        opacity: 0.5;
        transition: all 0.3s;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.home-page {
  @include media-breakpoint-down(lg) {
    .slider-a {
      background-color: $nude;
    }
  }
}
